<template>
  <div class="add-agency-user-role">
    <form @submit.prevent="$emit('create', roleData)">
      <div class="card">
        <div class="content px-3">
          <p class="header pt-2 pb-2">Add New Role</p>
          <fd-form-section title="Role Info">
            <fd-input
              v-model="roleData.name"
              class="col-12 sm-col-6 px-1 mb-2"
              label="Role Name"
              name="rolename"
              type="text"
              required
            >
            </fd-input>
            <fd-textarea
              v-model="roleData.description"
              class="col-12 px-1 mb-2"
              label="Description"
              name="description"
              type="text"
            >
            </fd-textarea>
          </fd-form-section>

          <!-- Admin/Branch Level Control -->
          <div class="col-12 my-2 mx-2">
            <div
              class="bordered-card p-2"
              :style="{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
                columnGap: '8px',
                rowGap: '8px'
              }"
            >
              <div class="d-flex align-items-center">
                <div class="p-2">
                  <div class="d-flex align-items-center my-1">
                    <div>Agency Admin</div>
                    <toggle-switch
                      v-model="roleData.isAgencyAdmin"
                      class="sm ml-2"
                    ></toggle-switch>
                  </div>
                  <div class="d-flex align-items-center my-1">
                    <div>Branch Admin</div>
                    <toggle-switch
                      v-model="roleData.isBranchAdmin"
                      class="sm ml-2"
                    ></toggle-switch>
                  </div>
                </div>
              </div>
              <div class="bg-grey-100 fg-grey-800 d-flex p-2">
                <i class="fas fa-circle-info mr-2 mt-1"></i>
                <div>
                  <p class="mb-1">
                    Leave both the toggles off if the role is neither branch or
                    agency admin.
                  </p>
                  <p>
                    If toggled on, Agency Admin will have agency level control
                    for the enabled permissions, while Branch Admin have only
                    branch level control (Cannot see other branch's data).
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Permission -->
          <fd-form-section title="Permission">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn main bordered"
                @click="clearPermission"
              >
                Clear
              </button>
              <button
                type="button"
                class="btn main bordered ml-1"
                @click="selectAllPermission"
              >
                Select All
              </button>
            </div>

            <div
              v-for="(permissions, permIndex) in roleData.permission"
              :key="permIndex"
              :class="permissionSectionClass"
            >
              <div>{{ _.startCase(permIndex) }}</div>
              <div class="my-2">
                <div
                  v-for="(permission, fieldIndex) in permissions"
                  :key="fieldIndex"
                >
                  <fd-checkbox
                    v-model="roleData.permission[permIndex][fieldIndex]"
                    class="col-12 sm-col-6 md-col-3"
                    :label="_.startCase(fieldIndex)"
                    :name="fieldIndex"
                  >
                  </fd-checkbox>
                </div>
              </div>
            </div>
          </fd-form-section>
        </div>
        <div class="footer p-2 text-right">
          <button class="btn mx-1" type="button" @click="$emit('close')">
            Cancel
          </button>
          <button class="btn main mx-1" type="submit">Create</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  components: {
    ToggleSwitch: () => import("@/components/GlobalComponents/ToggleSwitch")
  },
  mixins: [],
  props: {},
  data() {
    return {
      roleData: {
        agencyId: this.$route.params.id,
        name: "",
        description: "",
        isAgencyAdmin: false,
        isBranchAdmin: false,
        permission: {}
      },
      permissionSectionClass: "col-12 sm-col-6 md-col-4 px-1 mb-2"
    };
  },
  computed: {},
  watch: {
    "roleData.isAgencyAdmin": {
      handler(val) {
        if (this.roleData.isBranchAdmin && val) {
          this.roleData.isBranchAdmin = false;
        }
      }
    },
    "roleData.isBranchAdmin": {
      handler(val) {
        if (this.roleData.isAgencyAdmin && val) {
          this.roleData.isAgencyAdmin = false;
        }
      }
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted() {
    this.getAllPermissions();
  },
  methods: {
    async getAllPermissions() {
      try {
        const request = await this.$store.dispatch(
          "manageAgencyUserRole/getPermission",
          {}
        );

        this.roleData.permission = request;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch permission. Please try again later."
        });
      }
    },
    clearPermission() {
      for (let permission in this.roleData.permission) {
        this.roleData.permission[permission] = this._.mapValues(
          this.roleData.permission[permission],
          () => false
        );
      }
    },
    selectAllPermission() {
      for (let permission in this.roleData.permission) {
        this.roleData.permission[permission] = this._.mapValues(
          this.roleData.permission[permission],
          () => true
        );
      }
    }
  }
};
</script>

<style lang="scss">
.add-agency-user-role {
  .header {
    font-size: 24px;
    font-weight: bold;
  }
  .content {
    max-height: 80vh;
    overflow-y: auto;
  }
  .footer {
    box-shadow: 0px -2px 10px -2px #00000015;
  }
}
</style>
